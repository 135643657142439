<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>会员答题</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/game' }">游戏</el-breadcrumb-item>
                <el-breadcrumb-item>添加游戏</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-form ref="form" :model="form" style="padding: 48px 72px" label-position="right" label-width="auto">
            <el-form-item label="名字" prop="level" :rules="[{ required: true, message: '名字不能为空'},]">
                <el-input v-model="form.level" style="width: 240px" autofocus clearable />
            </el-form-item>

            <el-form-item label="解锁图标" label-position="left" prop="icon"
                :rules="[{ required: true, message: '图标不能为空'},]">
                <select-images @onSelect="onSelectIcon" @onDelete="onDeleteIcon" :image="icon"></select-images>
            </el-form-item>


            <el-form-item label="未解锁图标" label-position="left" prop="lock_icon" v-if="form.only_vip == 1"
                :rules="[{ required: true, message: '图标不能为空'},]">
                <select-images @onSelect="onSelectImage" @onDelete="onDelete" :image="image"></select-images>
            </el-form-item>



            <el-form-item label="排序" prop="sort" :rules="[{ required: true, message: '排序不能为空'},]">
                <el-input-number v-model="form.sort" :min="1" size="mini"></el-input-number>
            </el-form-item>

            <el-form-item label="解锁方式">
                <el-radio v-model="form.unlock_type" :label="0">无 </el-radio>
                <el-radio v-model="form.unlock_type" :label="1">顺序解锁</el-radio>
            </el-form-item>

            <el-form-item label="仅限会员">
                <el-radio v-model="form.only_vip" :label="0">否 </el-radio>
                <el-radio v-model="form.only_vip" :label="1">是</el-radio>
            </el-form-item>


            <el-form-item label="状态: " prop="status">
                <el-switch :active-value="1" :inactive-value="0" v-model="form.status" style="width: 240px" />
            </el-form-item>


            <el-form-item>
                <el-button size="mini" @click="$router.back()">取消</el-button>
                <el-button size="mini" @click="submit" :loading="loading" type="primary">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import SelectImages from "../../components/selectImages/index";
import { mapActions } from 'vuex'

export default {
    components: { SelectImages },
    data() {
        return {
            loading: false,
            image: '',
            icon: '',
            form: {
                unlock_type: 1,
                icon: '',
                sort: 1,
                only_vip: 0,
            }
        }
    },
    methods: {
        ...mapActions('game', ['setGameAdd']),
        onDelete() {
            this.image = ''
            this.form.lock_icon = ''
        },
        onDeleteIcon() {
            this.icon = ''
            this.form.icon = ''
        },
        onSelectIcon(image) {
            this.icon = imageUrl + image.path
            this.form.icon = image.path
        },
        onSelectImage(image) {
            this.image = imageUrl + image.path
            this.form.lock_icon = image.path
        },
        submit() {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    this.loading = true
                    const { data, res_info } = await this.setGameAdd(this.form)
                    this.loading = false
                    this.$router.go(-1)
                    if (res_info != 'ok') return
                    this.$message.success('新增成功！')
                }
            });
        },
    },
}
</script>
